<template>
  <b-card title="Copias de seguridad">
    <b-tabs>
      <b-tab title="Backups">
        <Backup @restaurarBackupComponent="restaurarBackupComponent"/>
      </b-tab>
      <b-tab title="Backups Restaurados">
        <RestaurarBackup :actualizar="actualizar"/>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BTabs, BTab, BCardText, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeColors } from "@themeConfig";
import Backup from "@/views/configuracion/backups/Backup.vue";
import RestaurarBackup from "@/views/configuracion/backups/RestaurarBackup.vue";

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    Backup,
    RestaurarBackup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      actualizar:false
    };
  },
  created() {},
  watch: {
  },
  methods: {
    restaurarBackupComponent(){
      this.actualizar=!this.actualizar;
    }
  },
};
</script>
<style lang="scss"></style>
