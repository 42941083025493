<template>
  <b-card no-body>
    <b-row>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-button variant="primary" @click="confirmarBackup()" class="mr-1">
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Realizar copia de seguridad</span>
        </b-button>

        <b-button @click="actualizarLista()" variant="secondary" class="btn-icon mr-1" >
          <feather-icon
            icon="RotateCcwIcon"
          />
        </b-button>

      </b-col>
      <b-col
        md="6"
        class="mb-1"
      >
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
          :show="loading"
        >
          <b-table class="sm" responsive="sm" 
              ref="selectableTable"
              selectable
              select-mode="single" 
              hover
              :small="true"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items" 
              :fields="fields"
              @row-selected="onRowSelected"
              show-empty
              empty-text="No se encontraron registros coincidentes"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              >
              <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
              </template>

              <template #cell(accion)="data">
                <div class="text-nowrap">
                    <b-button
                        @click="confirmarRestoreBackup(data.item.id)"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        v-b-tooltip.hover.bottom="'Restaurar Backup'"
                        class="btn-icon mr-1"
                        size="sm"
                    >
                        <feather-icon icon="RepeatIcon" />
                    </b-button>
                </div>
              </template>

              <template #cell()="data">
                <span class="text-nowrap">{{ data.value }}</span>
              </template>

          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <b-overlay :show="show" no-wrap></b-overlay>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BCardText, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
//import axios from 'axios'
export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BOverlay
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data(){
        return{
              items:[],
              fields: [
                    { key: 'fecha_hora', label: 'Fecha-Hora', sortable: false },
                    { key: 'user.name', label: 'Usuario', sortable: false },
                    { key: 'descripcion', label: 'Descripcion', sortable: false },
                    { key: 'peso', label: 'Peso', sortable: false },
                    'accion',
                ],
              selected: [],
              filter: null,
              filterOn: [],
              perPage: 10,
              pageOptions: [10, 25, 50],
              totalRows: 1,
              currentPage: 1,
              loading:false,
              show:false,
              submitted:false,
              errores:[],
        }
    },
    created(){        
        this.loading=true       
        this.listar();
    },  
    methods: {
        onRowSelected(items) {
          this.selected = items
        },
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
        selectThirdRow() {
          this.$refs.selectableTable.selectRow(2)
        },
        unselectThirdRow() {
          this.$refs.selectableTable.unselectRow(2)
        },
        onFiltered(filteredItems) {
          this.totalRows = filteredItems.length
          this.currentPage = 1
        },
        listar(){
            var url= '/admin/backups';
            axiosIns.get(url)
            .then(res => {
                this.items=res.data;
                this.totalRows = this.items.length
                this.loading=false
            })
            .catch(err =>{
                console.log(err);
            });
        },
        actualizarLista(){
          this.loading=true;
          this.listar();
        },
        confirmarBackup() {
            this.$swal({
                title: "Advertencia",
                text: "¡Está seguro que desea generar la copia de seguridad?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.show=true
                    this.backup();
                }
            })
        },
        backup(){
            axiosIns.get('/admin/backups/generar-backup')
            .then(res => {
                this.$bvToast.toast('Exito', {
                    title: 'Copia de seguridad se genero con éxito',
                    variant:'primary',
                    solid: false,
                })
                this.show=false
                this.actualizarLista();
                this.errores=[];
                this.submitted = false;
            })
            .catch(err =>{
                console.log(err);
                this.$bvToast.toast('Error', {
                    title: 'Imposible generar la copia de seguridad',
                    variant:'danger',
                    solid: false,
                })
                this.show=false
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        confirmarRestoreBackup(id) {
            this.$swal({
                title: "Advertencia",
                text: "¡Está seguro que desea restaurar la copia de seguridad?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Restaurar",
                cancelButtonText: "Cancelar",
                customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.show=true
                    this.restaurarBackup(id);
                }
            });
        },
        restaurarBackup(id) {
         axiosIns.get('/admin/backups/restaurar-backup/'+id)
          .then(res => {
                this.$bvToast.toast('Exito', {
                    title: 'Copia de seguridad se restauro con éxito',
                    variant:'primary',
                    solid: false,
                })
                this.show=false
                this.actualizarLista();
                this.errores=[];
                this.submitted = false;
                this.$emit('restaurarBackupComponent');
          }) 
          .catch(err =>{
                console.log(err);
                this.$bvToast.toast('Error', {
                    title: 'Imposible restaurar la copia de seguridad',
                    variant:'danger',
                    solid: false,
                })
                this.show=false
                this.submitted = false;
                this.errores=err.response.data.errors;
          });
        },
    },
}
</script>

<style lang="scss">

</style>